import React from 'react';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Color from '../../../primitives/colors';
import Divider from '../../../primitives/divider';
import HoverItemSet from '../../../primitives/hover-item-set';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import FadeTransition from '../../../animations/transitions/fade-transition';
import RowSet from '../../../primitives/grid/row-set';
import Check from '../../../primitives/media-item-with-decoration/check.svg';
import { FramedDevice, GenericDevice } from '../feature-set';

export type HoverFeatureSetTabletProps = Queries.HoverFeatureSetTabletPropsFragment;

const FeatureItem = ({ idx, title, text }: {
    idx: number
    title: string
    text: string
}): JSX.Element => (
    <motion.div
        css={{
            opacity: 0,
        }}
        initial={{
            opacity: 0,
            y: 20,
        }}
        whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.4, delay: idx * 0.1 },
        }}
    >
        <Color
            backgroundColor="primaryPurple"
            color="shadesWhite"
            css={{
                padding: '18px',
                borderRadius: borderRadius.medium,
                boxShadow: '0 12px 24px 0px rgba(87, 28, 199, 0.28)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                [breakpoints.mb]: {
                    borderRadius: borderRadius.large,
                },
            }}
        >
            <Check />
            <div css={{ marginLeft: '15px' }}>
                <Typography
                    fontSize={{
                        dt: 1322,
                        mb: 1120,
                    }}
                    fontWeight="medium"
                >
                    {title}
                </Typography>
                <Typography
                    fontSize={{
                        dt: 1322,
                        mb: 1120,
                    }}
                    css={{
                        opacity: 0.65,
                    }}
                >
                    {text}
                </Typography>
            </div>
        </Color>
    </motion.div>
);

const HoverFeatureSetTablet = ({
    richTitle, cards, link, orientation, overlays,
}: HoverFeatureSetTabletProps): JSX.Element => {
    const [value, setValue] = React.useState(0);
    const DeviceComponent = cards[value]?.showDeviceFrames ? FramedDevice : GenericDevice;
    return (
        <Color
            as={Container}
            backgroundColor="shadesWhite"
            css={{
                marginTop: '120px',
                hr: {
                    opacity: 0,
                },
                '&:not(:last-of-type)': {
                    hr: {
                        opacity: 1,
                    },
                },
                [breakpoints.mb]: {
                    marginTop: '70px',
                },
            }}
        >
            <Row css={{
                gridAutoFlow: 'column',
            }}
            >
                <Col breakpoints={{
                    dt: {
                        span: 12,
                        start: 0,
                        hidden: true,
                    },
                    mb: {
                        span: 4,
                        start: 0,
                        hidden: false,
                    },
                }}
                >
                    <Typography
                        override
                        css={{
                            paddingBottom: '36px',
                            [breakpoints.mb]: {
                                textAlign: 'center',
                                maxWidth: '84%',
                                margin: 'auto',
                            },
                        }}
                        fontWeight="light"
                        fontSize={{
                            dt: 4053,
                            mb: 2429,
                        }}
                    >
                        <CustomStructuredTextRenderer data={richTitle} />
                    </Typography>
                </Col>
                <Col breakpoints={{
                    dt: {
                        span: 6,
                        start: orientation === 'image-on-left' ? 0 : 6,
                    },
                    mb: {
                        span: 4,
                        start: 0,
                    },
                }}
                >
                    <div css={{
                        paddingBottom: '70px',
                        height: '100%',
                        [breakpoints.mb]: {
                            paddingBottom: '40px',
                        },
                    }}
                    >
                        <div
                            css={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                height: '100%',
                                justifyContent: 'flex-end',
                                width: '100%',
                                position: 'relative',
                                paddingLeft: '55px',
                                [breakpoints.mb]: {
                                    paddingLeft: '58px',
                                    paddingRight: '48px',
                                },
                            }}
                        >

                            <FadeTransition shouldChange={value.toString()}>
                                <div css={{ width: '100%', margin: 'auto', position: 'relative' }}>
                                    <DeviceComponent device={cards[value].device} mediaItem={cards[value].mediaItem}>
                                        <div
                                            aria-hidden="true"
                                            className="decoration"
                                            css={{
                                                position: 'absolute',
                                                left: (() => {
                                                    if (cards[value].device === 'mobile' && orientation !== 'decoration-on-right') {
                                                        return '-120px';
                                                    }
                                                    return orientation === 'decoration-on-right' ? 'unset' : '-55px';
                                                })(),
                                                right: (() => {
                                                    if (cards[value].device === 'mobile' && orientation === 'decoration-on-right') {
                                                        return '-120px';
                                                    }
                                                    return orientation !== 'decoration-on-right' ? 'unset' : '-55px';
                                                })(),
                                                bottom: '70px',
                                                minWidth: '266px',
                                                zIndex: '2',
                                                [breakpoints.tb]: {
                                                    transform: 'scale(0.8)',
                                                    bottom: '-40px',
                                                },
                                                [breakpoints.mb]: {
                                                    transform: 'scale(0.5)',
                                                    bottom: '0px',
                                                    left: (() => {
                                                        if (orientation === 'image-on-left') {
                                                            return 'unset';
                                                        }
                                                        return '-100px';
                                                    })(),
                                                    right: (() => {
                                                        if (orientation !== 'image-on-left') {
                                                            return 'unset';
                                                        }
                                                        return '-100px';
                                                    })(),
                                                },
                                            }}
                                        >
                                            <RowSet breakpoints={{ dt: { between: 12 } }}>
                                                {!cards[value].hideOverlays && overlays && overlays.map(({ title, text }, idx) => (
                                                    <FeatureItem title={title} text={text} idx={idx} key={title} />
                                                ))}
                                            </RowSet>
                                        </div>
                                    </DeviceComponent>
                                </div>
                            </FadeTransition>
                        </div>
                    </div>
                </Col>
                <Col breakpoints={{
                    dt: {
                        span: 6,
                        start: orientation === 'image-on-left' ? 6 : 0,
                    },
                    mb: {
                        span: 4,
                        start: 0,
                    },
                }}
                >
                    <Typography
                        override
                        css={{
                            paddingBottom: '36px',
                            [breakpoints.mb]: {
                                textAlign: 'center',
                                maxWidth: '84%',
                                margin: 'auto',
                                display: 'none',
                            },
                        }}
                        fontWeight="light"
                        fontSize={{
                            dt: 4053,
                            mb: 2429,
                        }}
                    >
                        <CustomStructuredTextRenderer data={richTitle} />
                    </Typography>
                    <HoverItemSet
                        cards={cards}
                        link={link}
                        setValue={setValue}
                    />
                </Col>
            </Row>
            <Divider />
        </Color>
    );
};
export default HoverFeatureSetTablet;

export const query = graphql`
    fragment HoverFeatureSetTabletProps on DatoCmsHoverFeatureSetTablet {
        orientation
        richTitle {
            blocks
            links
            value
        }
        cards {
            title
            text
            device
            showDeviceFrames
            link {
                ...LinkWithTitleProps
            }
            mediaItem {
                ...MediaItemProps
            }
            hideOverlays
        }
        link {
            ...LinkWithTitleProps
        }
        overlays {
            title
            text
            colour {
                colour {
                    hex
                }
            }
        }
    }
`;
